import React from 'react';
import CheckboxGroup from '../../../../components/CheckboxGroup/CheckboxGroup';
import {
  LIST_OF_PERKS,
  LIST_OF_PERKS_VALUES,
  LOYALTY_PROGRAM_KEYS,
  LOYALTY_PROGRAM_NAMES,
} from '../../../../constans/search';

import styles from './LoyaltyProgramsTable.module.scss';
import commonStyles from '../../common.module.scss';

const LoyaltyProgramsTable = () => {
  const lastPerkId = LIST_OF_PERKS.length - 1;

  return (
    <div className={styles.tableWrapper}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.tableHeadersItem}>
              Lotality program name
            </th>
            {LIST_OF_PERKS.map((perk, index) => (
              <th className={styles.tableHeadersItem} key={index}>
                {perk}
                {(index === lastPerkId) && (
                  <div className={styles.subtitle}>
                    (if available)
                  </div>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {LOYALTY_PROGRAM_NAMES.map((program, index) => (
            <tr
              key={index}
              className={styles.tableRow}
            >
              <td>{program}</td>
              <CheckboxGroup
                wrap="td"
                name={`loyaltyPrograms.${LOYALTY_PROGRAM_KEYS[index]}`}
                checkboxClassName={commonStyles.checkbox}
                titleClassName={commonStyles.checkboxNameless}
                options={LIST_OF_PERKS_VALUES}
              />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LoyaltyProgramsTable;
