import React from 'react';
import { FieldArray } from 'formik';

import InputDropDowned from '../../../../components/UI/InputDropDowned/InputDropDowned';
import CounterInputBlock from '../../../../components/UI/CounterInputBlock/CounterInputBlock';
import { MIN_ADULTS, ROOM_MAX_PEOPLE } from '../../../../constans/validation';
import {
  ADULT_PREFIX, KIDS_PREFIX, MAX_KIDS, MAX_KIDS_AGE, MIN_KIDS_AGE,
} from '../../../../constans/search';
import { integerPipeWithLimits } from '../../../../helpers/helper-methods';
import { Input } from '../../../../components/UI/Input/Input';

import styles from '../../search-page.module.scss';
import inputStyles from './GuestsInput.module.scss';

const GuestsInput = ({
  values,
  changeMathValue,
  kidsAgeInputName,
  showErrors,
  errors,
}) => {
  const displayValueFromDropDownInputs = (adultsAmount, kidsAmount = '') => `
      ${adultsAmount ? `${adultsAmount}${ADULT_PREFIX}` : ''} 
      ${kidsAmount > 0 ? `${adultsAmount ? '+' : ''} ${kidsAmount}${KIDS_PREFIX}` : ''}
    `;

  return (
    <InputDropDowned
      errored={showErrors && errors?.[kidsAgeInputName]?.length > 0}
      id="guests"
      placeHolder="Add guests"
      inputValues={displayValueFromDropDownInputs(values.adultGuests, values.kidGuests)}
    >
      <FieldArray
        name={kidsAgeInputName}
        render={({ push, pop }) => {
          const handleIncrease = () => {
            changeMathValue('kidGuests', Number(values.kidGuests), 'up');
            push('');
          };

          const handleDecrease = () => {
            changeMathValue('kidGuests', Number(values.kidGuests));
            pop();
          };

          return (
            <>
              <CounterInputBlock
                inputMaxLength={ROOM_MAX_PEOPLE}
                labelText="Adults"
                reduceValue={() => changeMathValue('adultGuests', Number(values.adultGuests))}
                increaseValue={() => changeMathValue('adultGuests', Number(values.adultGuests), 'up')}
                inputName="adultGuests"
                inputClassName={inputStyles.input}
                reduceDisabled={values.adultGuests <= MIN_ADULTS}
                increaseDisabled={values.adultGuests >= ROOM_MAX_PEOPLE}
              />
              <CounterInputBlock
                inputMaxLength={MAX_KIDS}
                labelText="Kids"
                inputName="kidGuests"
                inputClassName={inputStyles.input}
                reduceValue={handleDecrease}
                increaseValue={handleIncrease}
                reduceDisabled={!values.kidGuests}
                increaseDisabled={values.kidGuests >= ROOM_MAX_PEOPLE}
              />
              {!!values.kidGuests && (
                <div className={styles.kids}>
                  <p>Age of kids at check out</p>
                  {values[kidsAgeInputName].map((_kid, index) => (
                    <Input
                      key={index}
                      containerStyles={styles.kidsInput}
                      formater={(age) => integerPipeWithLimits({
                        value: age,
                        maxValue: MAX_KIDS_AGE,
                        minValue: MIN_KIDS_AGE,
                      })}
                      type="text"
                      placeholder="Kid's age"
                      name={`${kidsAgeInputName}.${index}`}
                    />
                  ))}
                </div>
              )}
            </>
          );
        }}
      />
    </InputDropDowned>
  );
};

GuestsInput.defaultProps = {
  kidsAgeInputName: 'kids',
  showErrors: false,
  errors: {},
};

export default GuestsInput;
