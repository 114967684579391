import React from 'react';

import SearchPage from '../../views/search/search-page';
import RoleBasedRoute from '../../components/RoleBasedRoute';
import { nonSellerRoles } from '../../constans/roles';

function Page({ location }) {
  return (
    <RoleBasedRoute
      availableRoles={nonSellerRoles}
      component={() => <SearchPage location={location} />}
    />
  );
}

export default Page;
