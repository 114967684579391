import React, { Fragment } from 'react';
import { Formik, Form } from 'formik';

import CheckboxGroup from '../../../../components/CheckboxGroup/CheckboxGroup';
import Modal from '../../../../components/UI/Modal/Modal';
import {
  SEARCH_FILTERS_MODAL_INITIAL_VALUES,
  FILTERS_MODAL_FIELDS,
} from '../../../../constans/search';

import styles from './FiltersModal.module.scss';
import commonStyles from '../../common.module.scss';
import { objectIsNotEmpty } from '../../../../helpers/helper-methods';

const FiltersModal = ({
  onSubmit, isShow, initialValues, closeModalHandler,
}) => {
  const handleSubmit = (value) => onSubmit(value);

  return (
    <Modal
      show={isShow}
      displayToggle={closeModalHandler}
      modalClasses={styles.modal}
    >
      <div className="h4-title">
        Add filters
      </div>
      <Formik
        initialValues={objectIsNotEmpty(initialValues)
          ? initialValues : SEARCH_FILTERS_MODAL_INITIAL_VALUES}
        onSubmit={handleSubmit}
      >
        {({ resetForm }) => (
          <Form>
            <div className={styles.modalContent}>
              {FILTERS_MODAL_FIELDS.map(({ label, options, name }) => (
                <Fragment key={name}>
                  <p className="infoTextSubtitle t-700 uppercased-text">
                    {label}
                  </p>
                  <div className="flex flex-between flex-wrap">
                    <CheckboxGroup
                      name={name}
                      options={options}
                      valueField="value"
                      checkboxClassName={styles.checkbox}
                      wrapperClassName={styles.checkboxWrapper}
                      titleClassName={`${styles.checkboxText} ${commonStyles.label}`}
                    />
                  </div>
                </Fragment>
              ))}
            </div>
            <hr className={styles.divider} />
            <div className={styles.modalFooter}>
              <button
                type="button"
                className="btn btn-uppercased btn_mld t-600 text-left"
                onClick={() => resetForm({ values: SEARCH_FILTERS_MODAL_INITIAL_VALUES })}
              >
                reset all
              </button>
              <button
                type="submit"
                className="btn btn-uppercased btn_common btn_mld t-600"
              >
                apply
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default FiltersModal;
