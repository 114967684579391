import React, { useMemo, useState } from 'react';
import moment from 'moment';

import Calendar from '../../../../components/Calendar/Calendar';
// import InfoTooltip from '../../../../components/InfoTooltip/InfoTooltip';
// import CustomSelect from '../../../../components/UI/CustomSelect/CustomSelect';
import InputDropDowned from '../../../../components/UI/InputDropDowned/InputDropDowned';
import InputWrapper from '../../../../components/UI/InputWrapper/InputWrapper';
import Label from '../../../../components/UI/Label/Label';
// import { DATE_TYPES_FIELD_NAME, PERIODS_DROPDOWN_OPTIONS } from '../../../../constans/search';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';
import { PHONE_WIDTH_BREAKPOINT } from '../../../../constans/header';
import Modal from '../../../../components/UI/Modal/Modal';
import { DEFAULT_FORMAT } from '../../../../constans/formats';

import styles from './DateInput.module.scss';
// import commonStyles from '../../common.module.scss';

const DateInput = ({
  values,
  setFieldValue,
  calendarFields,
  errors,
  label = 'Check-in / Check-out',
  children,
}) => {
  const [isShown, setIsShown] = useState(false);
  const width = useWindowWidth();
  const { checkin, checkout, flexPeriod } = values;

  const displayValues = (start, end, flex) => {
    const startDate = moment(start).format(DEFAULT_FORMAT);
    const endDate = moment(end).format(DEFAULT_FORMAT);

    return `${startDate} - ${endDate} ${flex ? `+-${flex}days` : ''}`;
  };

  const createInput = useMemo(() => (
    <>
      <Calendar
        setFieldValue={setFieldValue}
        fields={calendarFields}
        checkin={checkin}
        checkout={checkout}
      >
        {children}
      </Calendar>
      {/* ! will be used again later */}
      {/* <div className="flex flex-between flex-v-center">
        <button
          type="button"
          className={`
            ${styles.checkinButton}
            ${!values.datesType && styles.checkinButtonActive}
          `}
          onClick={() => setFieldValue(DATE_TYPES_FIELD_NAME, 0)}
        >
          Fixed
        </button>
        <button
          type="button"
          className={`
            ${styles.checkinButton}
            ${values.datesType && styles.checkinButtonActive}
          `}
          onClick={() => setFieldValue(DATE_TYPES_FIELD_NAME, 1)}
        >
          Flexible
        </button>
      </div> */}
      {/* {!!values.datesType && (
        <>
          <div className={styles.checkinFooter}>
            <div className={commonStyles.label}>
              Duration:
              <InfoTooltip>
                sample text
              </InfoTooltip>
            </div>
          </div>
          <CustomSelect
            options={PERIODS_DROPDOWN_OPTIONS}
            name="flexPeriod"
          />
        </>
      )} */}
    </>
  ), [values, setFieldValue]);

  return (
    <>
      <InputWrapper containerClasses={`${styles.checkin} col-12 col-lg`}>
        <Label>{label}</Label>
        {width < PHONE_WIDTH_BREAKPOINT
          ? (
            <>
              <div
                onClick={() => setIsShown(true)}
                className={`${styles.fake} ${errors?.checkin
                  || errors?.checkout
                  ? styles.errored : ''}`}
              >
                {(checkin && checkout) ? displayValues(checkin, checkout, flexPeriod) : 'Select date'}
              </div>
              <Modal
                displayToggle={() => setIsShown(false)}
                modalClasses={styles.modal}
                show={isShown}
              >
                {createInput}
              </Modal>
            </>
          )
          : (
            <InputDropDowned
              errored={errors?.checkin || errors?.checkout}
              id="datepicker"
              inputValues={(checkin && checkout) ? displayValues(checkin, checkout, flexPeriod) : 'Select date'}
              placeHolder="Select dates"
              displayedValueClassName={styles.inputDropdownedValue}
              dropdownClassName={styles.calendarWrapper}
            >
              {createInput}
            </InputDropDowned>
          )}
        {errors?.checkout ? <div className="error-input-msg">{errors.checkout}</div> : null}
      </InputWrapper>
    </>
  );
};

DateInput.defaultProps = {
  errors: {},
};

export default DateInput;
