import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Formik, Form, Field } from 'formik';
import qs from 'qs';
import { navigate } from 'gatsby';
import {
  object, date, number, ref,
} from 'yup';
import moment from 'moment';

import StorageService from '../../services/storage.service';
import SearchService from '../../services/search.service';
import Layout from '../../components/Layout/Layout';
import InputDropDowned from '../../components/UI/InputDropDowned/InputDropDowned';
import CounterInputBlock from '../../components/UI/CounterInputBlock/CounterInputBlock';
import InputWrapper from '../../components/UI/InputWrapper/InputWrapper';
import Label from '../../components/UI/Label/Label';
import CustomSelect from '../../components/UI/CustomSelect/CustomSelect';
import ExpandableBlock from '../../components/ExpandableBlock/ExpandableBlock';
import FormikRadioGroup from '../../components/RadioGroup/RadioGroup';
import GuestsInput from './FormElements/GuestsInput/GuestsInput';
import CheckboxGroup from '../../components/CheckboxGroup/CheckboxGroup';
import FiltersModal from './FormElements/FiltersModal/FiltersModal';
import Checkbox from '../../components/UI/Checkbox/Checkbox';
import InfoTooltip from '../../components/InfoTooltip/InfoTooltip';
import DateInput from './FormElements/DateInput/DateInput';
import { useLockBodyScroll } from '../../hooks/useBodyScrollLock';
import LoyaltyProgramsTable from './FormElements/LoyaltyProgramsTable/LoyaltyProgramsTable';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import {
  AMBIANCE_DROPDOWN_VALUES,
  DEFAULT_PROFILE_NAME,
  FOCUS_DROPDOWN_OPTIONS,
  INSURANCE_OPTIONS,
  LOUNGE_SECTION_OPTIONS,
  CONFIDENT_OPTIONS,
  REQUIRED_OPTIONS,
  PROPERTY_TYPE_OPTIONS,
  CAPACITY_OPTIONS,
  CAPACITY_OPTIONS_DESCRIPTIONS,
  SEARCH_PAGE_INITIAL_VALUES,
  PROFILE_KEYS,
  EXECUTIVE_LOUNGE_INFO_TOOLTIP,
  LOYALTY_PROGRAMS_INFO_TOOLTIP,
  MIN_ROOMS_STATEMENT,
  DEFAULT_PREV_SEARCH_VALUE,
} from '../../constans/search';
import BudgetInput from '../../components/BudgetInput';
import SelectDestination from '../../components/SelectDestination';
import { MAX_ROOMS } from '../../constans/validation';
import { DEFAULT_FORMAT } from '../../constans/formats';
import { searchProfileDefaultInstance } from '../../constans/form-fields';
import { searchAgeOfKidsValidationSchema } from '../../constans/validation-schemas';

import commonStyles from './common.module.scss';
import styles from './search-page.module.scss';

const createSearchProfile = (searchProfile) => {
  const userProfile = {};

  Object
    .entries(searchProfile)
    .forEach(([key, value]) => {
      if (PROFILE_KEYS.includes(key)) {
        userProfile[key] = value;
      }
    });

  return userProfile;
};

const validationSchema = object().shape({
  minPrice: number().max(ref('maxPrice'), 'Can\'t be greater than max price'),
  checkin: date().required(),
  checkout: date()
    .test({
      name: 'MinEndDate',
      test(value) {
        return this.parent.checkin >= value ? this.createError({
          message: `Check out date must be late than ${moment(this.parent.checkin).format(DEFAULT_FORMAT)}`,
          path: 'checkout',
        }) : true;
      },
    }),
}).concat(searchAgeOfKidsValidationSchema);

const SearchPage = () => {
  const storage = useMemo(() => StorageService(), []);
  const searchData = useMemo(() => storage
    .get(LOCALSTORAGE_KEYS.SEARCH, DEFAULT_PREV_SEARCH_VALUE), [storage]);
  const formInitData = useMemo(() => ({
    ...SEARCH_PAGE_INITIAL_VALUES,
    ...searchData.values,
  }), []);
  const [filters, setFilters] = useState(searchData.filters || {});
  const [isModalShown, setIsModalShown] = useState(false);
  const searchService = useMemo(() => SearchService(), []);
  const filtersCount = useMemo(() => (Object.values(filters).flat().length), [filters]);

  const displaySelectedPropertyTypes = (selectedProperyTypes) => PROPERTY_TYPE_OPTIONS
    .filter(({ value }) => selectedProperyTypes.includes(value))
    .map(({ title }) => title);

  const displaySelectedRequiredOptions = (selectedOptions) => REQUIRED_OPTIONS
    .filter(({ value }) => selectedOptions.includes(value))
    .map(({ title }) => title);

  const displayAddedFiltersText = useCallback(() => (filtersCount > 0
    ? `Added filters - ${filtersCount}`
    : 'add filters'
  ), [filtersCount]);

  const handleModalSubmit = (values) => {
    setFilters(values);
    setIsModalShown(false);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    let userProfile = {};
    const user = storage.get(LOCALSTORAGE_KEYS.USER, {});
    const profilingResults = storage.get(LOCALSTORAGE_KEYS.PROFILING, null);

    // User filled profiling form
    if (profilingResults) {
      userProfile = createSearchProfile(profilingResults);
    }

    // Authorized user doesn't filled profile form
    if (user?.searchProfile && !profilingResults) {
      userProfile = createSearchProfile(user.searchProfile);
    }

    // UnAuthorized user doesn't filled profile form
    if (!user?.searchProfile && !profilingResults) {
      userProfile = createSearchProfile(searchProfileDefaultInstance);
    }

    const { locationName, ...requestValues } = values;

    const { data: { uuid } } = await searchService
      .createSearchUUID({ ...requestValues, userProfile });

    const processedFilters = {};
    const {
      adultGuests,
      kidGuests,
      requiredOptions,
      checkin,
      checkout,
      kids,
      currency,
      rooms,
    } = values;

    Object.values(filters)
      .flat()
      .forEach((filter) => { processedFilters[filter] = true; });

    // ! once upon a time it wont fit url string limit
    const query = qs.stringify({
      requestId: uuid,
      adultGuests,
      kidGuests,
      requiredOptions,
      checkin,
      checkout,
      kids,
      currency,
      numberOfRooms: rooms,
      searchLocation: locationName,
      ...processedFilters,
    });

    const propertiesModel = {
      uuid,
      countryId: values?.countryId,
      regionId: values?.regionId,
      cityId: values?.cityId,
      districtId: values?.districtId,
      offset: 0,
      limit: 0,
      ...processedFilters,
    };

    storage.set(LOCALSTORAGE_KEYS.SEARCH, {
      values,
      filters,
    });

    const { pagination } = await searchService.getPropertiesByUUID(propertiesModel);
    setSubmitting(false);

    if (pagination.totalCount > 0) {
      storage.remove(LOCALSTORAGE_KEYS.PROFILING);

      navigate(`/results?${query}`);
      return;
    }

    const nothingFoundQuery = qs.stringify({
      requestId: uuid,
    });

    navigate(`/nothing-found?${nothingFoundQuery}`);
  };

  const { disable, enable } = useLockBodyScroll();

  useEffect(() => {
    if (isModalShown) {
      disable();
    } else enable();

    return () => enable();
  }, [isModalShown]);

  return (
    <div className="column-flexed-wrapper search-page">
      <Layout>
        <div className={`blue-grad-bg flex-grow-1 ${styles.bg}`}>
          <div className="container">
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={validationSchema}
              initialValues={formInitData}
              onSubmit={handleSubmit}
            >
              {({
                values, setFieldValue, isSubmitting, errors, handleBlur,
              }) => {
                const changeMathValue = (field, value, direction) => {
                  setFieldValue(field, direction ? value + 1 : value - 1);
                };

                return (
                  <div className="p-0 col-xl-10 m-auto">
                    <div className={`${styles.card} ${styles.mt60} container-bordered`}>
                      <div className="block-title">
                        Search parameters
                      </div>
                      <SelectDestination
                        setFieldValue={setFieldValue}
                        initialValue={{
                          name: values?.locationName,
                          city: { id: values?.cityId },
                          country: { id: values?.countryId },
                          region: { id: values?.regionId },
                          district: { id: values?.districtId },
                        }}
                      />
                      <Form>
                        <div className="row">
                          <InputWrapper containerClasses="col-12 col-lg">
                            <Label>Guests</Label>
                            <GuestsInput
                              showErrors
                              errors={errors}
                              values={values}
                              changeMathValue={changeMathValue}
                            />
                          </InputWrapper>

                          <InputWrapper containerClasses="col-12 col-lg">
                            <Label>Rooms</Label>
                            <InputDropDowned
                              id="rooms"
                              placeHolder="Rooms"
                              inputValues={values.rooms ? values.rooms > 1 ? `${values.rooms} rooms` : `${values.rooms} room` : 'Add rooms'}
                            >
                              <CounterInputBlock
                                labelText="Number of rooms"
                                inputName="rooms"
                                reduceDisabled={!values.rooms || values.rooms < MIN_ROOMS_STATEMENT}
                                increaseDisabled={values.rooms >= MAX_ROOMS}
                                inputClassName={commonStyles.untouchable}
                                reduceValue={() => changeMathValue('rooms', values.rooms)}
                                increaseValue={() => changeMathValue('rooms', values.rooms, 'up')}
                              />
                              <div>
                                {CAPACITY_OPTIONS.map(({ label, key }, index) => (
                                  <Checkbox
                                    key={key}
                                    containerClasses={`
                                      ${commonStyles.checkboxContainer}
                                      ${commonStyles.checkboxDropdowned}
                                    `}
                                    checkboxClasses={`
                                      ${commonStyles.checkbox}
                                      c-custom__check-sm
                                      c-custom__check-bordered-primary
                                      c-custom__custom-input
                                      c-custom__check
                                    `}
                                    textClasses={`
                                      ${commonStyles.checkboxText}
                                      ${commonStyles.label}
                                      flex flex-v-center
                                    `}
                                    labelClasses="flex flex-v-center"
                                    name={key}
                                  >
                                    {label}
                                    <InfoTooltip>
                                      {CAPACITY_OPTIONS_DESCRIPTIONS[index]}
                                    </InfoTooltip>
                                  </Checkbox>
                                ))}
                              </div>
                            </InputDropDowned>
                          </InputWrapper>
                          <DateInput
                            errors={errors}
                            setFieldValue={setFieldValue}
                            values={values}
                            calendarFields={['checkin', 'checkout']}
                          />
                        </div>
                        <div className="row">
                          <BudgetInput
                            errors={errors}
                            values={values}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                          />
                          <InputWrapper containerClasses="col-12 col-lg">
                            <Label>Property type</Label>
                            <InputDropDowned
                              id="propertyType"
                              placeHolder="Select options"
                              inputValues={displaySelectedPropertyTypes(values.propertyType).join(', ')}
                              displayedValueClassName={styles.inputDropdownedValue}
                            >
                              <CheckboxGroup
                                name="propertyType"
                                valueField="value"
                                checkboxClassName={commonStyles.checkbox}
                                wrapperClassName={commonStyles.checkboxDropdowned}
                                titleClassName={`${commonStyles.checkboxText} ${commonStyles.label}`}
                                options={PROPERTY_TYPE_OPTIONS}
                              />
                            </InputDropDowned>
                          </InputWrapper>

                          <InputWrapper containerClasses="col-12 col-lg">
                            <Label>Required options</Label>
                            <InputDropDowned
                              id="requiredOptions"
                              placeHolder="Select options"
                              inputValues={displaySelectedRequiredOptions(values.requiredOptions).join(', ')}
                              displayedValueClassName={styles.inputDropdownedValue}
                            >
                              <CheckboxGroup
                                name="requiredOptions"
                                valueField="value"
                                checkboxClassName={commonStyles.checkbox}
                                wrapperClassName={commonStyles.checkboxDropdowned}
                                titleClassName={`${commonStyles.checkboxText} ${commonStyles.label}`}
                                options={REQUIRED_OPTIONS}
                              />
                            </InputDropDowned>
                          </InputWrapper>
                        </div>

                        <div className="row">
                          <InputWrapper containerClasses="col-12 col-lg">
                            <Label>Choose profile</Label>
                            <CustomSelect
                              options={[DEFAULT_PROFILE_NAME]}
                              name="profile"
                              disabled
                            />
                          </InputWrapper>

                          <InputWrapper containerClasses="col-12 col-lg">
                            <Label>Focus of the trip</Label>
                            <CustomSelect
                              options={FOCUS_DROPDOWN_OPTIONS}
                              name="focus"
                            />
                          </InputWrapper>

                          <InputWrapper containerClasses="col-12 col-lg">
                            <Label>Ambiance for this trip</Label>
                            <CustomSelect
                              options={AMBIANCE_DROPDOWN_VALUES}
                              name="ambiance"
                            />
                          </InputWrapper>
                        </div>

                        <ExpandableBlock
                          containerStyles={`
                            ${styles.expandableHeaderWrapper} 
                            ${styles.notOverflowed}
                          `}
                          header={(
                            <div className={`${styles.expandableHeader} flex flex-v-center`}>
                              Executive lounge
                              <InfoTooltip>
                                {EXECUTIVE_LOUNGE_INFO_TOOLTIP}
                              </InfoTooltip>
                            </div>
                          )}
                        >
                          <div className={styles.loungeWrapper}>
                            <div className={`${styles.loungeRow} flex flex-between flex-v-center`}>
                              <div className={commonStyles.label}>Free breakfast in lounge:</div>
                              <Field
                                name="freeBreakfastInLounge"
                                options={LOUNGE_SECTION_OPTIONS}
                                component={FormikRadioGroup}
                              />
                            </div>

                            <div className={`${styles.loungeRow} flex flex-between flex-v-center`}>
                              <div className={commonStyles.label}>
                                Alcoholic drinks in lounge:
                              </div>
                              <Field
                                name="alcoholicDrinksInLounge"
                                options={LOUNGE_SECTION_OPTIONS}
                                component={FormikRadioGroup}
                              />
                            </div>

                            <div className={`${styles.loungeRow} flex flex-between flex-v-center`}>
                              <div className={commonStyles.label}>
                                Food, non-alcoholic drinks and other lounge perks:
                              </div>
                              <Field
                                name="foodAndNonAlcoholicDrinks"
                                options={LOUNGE_SECTION_OPTIONS}
                                component={FormikRadioGroup}
                              />
                            </div>
                          </div>
                        </ExpandableBlock>
                        <ExpandableBlock
                          containerStyles={styles.expandableHeaderWrapper}
                          header={(
                            <div className={styles.expandableHeader}>
                              Cancellation
                            </div>
                          )}
                        >
                          <div className={styles.loungeWrapper}>
                            <div className={`${styles.cancellationRow} flex flex-v-center`}>
                              <Checkbox
                                checkboxClasses={`
                                  ${commonStyles.checkbox} 
                                  c-custom__check-bordered-primary c-custom__check-sm
                                `}
                                labelClasses={`${commonStyles.checkboxText} ${commonStyles.label}`}
                                name="noRefund"
                              >
                                Value non-refundability feature
                              </Checkbox>
                            </div>

                            <div className={`${styles.loungeRow} flex flex-v-center`}>
                              <div className={commonStyles.label}>
                                Travel insurance:
                                <InfoTooltip>
                                  Do you have travel insurance (or credit card benefit)
                                  that entitles you to a refund under most cancellation
                                  scenarios (besides changing your mind)?
                                </InfoTooltip>
                              </div>
                              <Field
                                disabled={!values.noRefund}
                                name="travelInsurance"
                                options={INSURANCE_OPTIONS}
                                component={FormikRadioGroup}
                              />
                            </div>

                            <div className={`${styles.loungeRow} flex flex-v-center`}>
                              <div className={commonStyles.label}>
                                Confident on booking:
                                <InfoTooltip>
                                  <p className={styles.tooltipText}>
                                    How confident are you that you will not
                                    need or want to cancel this booking?
                                  </p>
                                  <p className={styles.tooltipText}>
                                    If you are not at least
                                    <span>
                                      &quot;fairly confiden&quot;
                                    </span>
                                    , we recommend that you exclude
                                    <span>
                                      non-refundable Offers
                                      {' '}
                                    </span>
                                    from your search.
                                  </p>
                                </InfoTooltip>
                              </div>
                              <Field
                                disabled={!values.noRefund}
                                name="confidentOnBooking"
                                options={CONFIDENT_OPTIONS}
                                component={FormikRadioGroup}
                              />
                            </div>
                          </div>
                        </ExpandableBlock>
                        <ExpandableBlock
                          disabled
                          btnDisabled
                          containerStyles={`
                            ${styles.expandableHeaderWrapper} 
                            ${styles.notOverflowed}
                          `}
                          header={(
                            <div className={`${styles.expandableHeader} flex flex-v-center`}>
                              <span className={`${styles.expandableHeaderInner} `}>
                                Loyalty programs
                              </span>
                              <InfoTooltip>
                                {LOYALTY_PROGRAMS_INFO_TOOLTIP}
                              </InfoTooltip>
                            </div>
                          )}
                        >
                          <LoyaltyProgramsTable />
                        </ExpandableBlock>
                        <div className={styles.buttons}>
                          <button
                            type="button"
                            onClick={() => setIsModalShown(true)}
                            className={`
                              btn btn_brs-28 btn-uppercased btn_mld btn_bordered t-700
                              ${filtersCount && 'btn_inverted'}
                            `}
                          >
                            {displayAddedFiltersText()}
                          </button>
                          <button
                            disabled={isSubmitting || !values?.locationName}
                            type="submit"
                            className="btn btn-uppercased btn_common btn_mld t-700"
                          >
                            submit
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
        <FiltersModal
          closeModalHandler={() => setIsModalShown(false)}
          initialValues={filters}
          onSubmit={handleModalSubmit}
          isShow={isModalShown}
        />
      </Layout>
    </div>
  );
};

export default SearchPage;
